//import getters from './getters'
import actions from "./actions";
//import mutations from './mutations'

const state = {
  isRegConfirm: false,
  regData: null,
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  currentUser: {},
  availableTalants: {},
  userTalants: {},
  userTools: {},
  userStyles: {},
  showInputResetCode: false,
  audioFiles: [],
  currentAudioId: null, // ID текущего проигрываемого файла
  audioStatus: 'stopped', // Статус проигрывания ('playing', 'paused', 'stopped')
  audioElement: null, // Элемент аудио
};


const getters = {
  isRegConfirm: (state) => state.isRegConfirm,
  regData: (state) => state.regData,
  isLoggedIn: (state) => state.isLoggedIn,
  currentUser: (state) => state.currentUser,
  availableTalants: (state) => state.availableTalants,
  userTalants: (state) => state.userTalants,
  userTools: (state) => state.userTools,
  userStyles: (state) => state.userStyles,
  // userAudioFiles: (state) => state.currentUser.audioFiles,
  userStatus: (state) => state.currentUser.Status,
  showInputResetCode: (state) => state.showInputResetCode,
  startTimer: (state) => state.startTimer,
  audioFiles:(state)=>state.audioFiles
};

const mutations = {
  DELETE_USER_TALANT(state, talantCode) {
    state.userTalants = state.userTalants.filter(talant => talant.Code !== talantCode);
    state.availableTalants.Talants = state.availableTalants.Talants.filter(talent => talent.Code !== talantCode);

  },
  SET_SHOW_INPUT_RESET_CODE(state, value) {
    state.showInputResetCode = value;
  },
  
  SET_REGDATA(state, data) {
    state.regData = data;
  },
  SET_REGCONFIRM(state, status) {
    state.isRegConfirm = status;
  },
  SET_LOGIN(state, status) {
    state.isLoggedIn = status;
    localStorage.setItem('isLoggedIn', status ? 'true' : 'false');
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = user;
  },
  SET_CURRENT_USER_AVAILABLE_TALANTS(state, talants) {
    state.availableTalants = talants;
  },
  SET_CURRENT_USER_TALANTS(state, talants) {
    state.userTalants = talants;
  },
  SET_CURRENT_USER_TOOLS(state, tools) {
    state.userTools = tools;
  },
  SET_CURRENT_USER_STYLES(state, styles) {
    state.userStyles = styles;
  },
  UpdateFirstName(state, value) {
    state.currentUser.FirstName = value;
  },
  UpdateLastName(state, value) {
    state.currentUser.LastName = value;
  },
  UpdateBirthDate(state, value) {
    state.currentUser.BirthDate = value;
  },
  UpdateAbout(state, value) {
    state.currentUser.AboutMe = value;
  },
  UpdateNickName(state, value) {
    state.currentUser.NickName = value;
  },
  UpdateAvatar(state, value) {
    state.currentUser.Avatar = value;
  },
  UpdateSex(state, value) {
    state.currentUser.Sex = value;
  },
  // SET_USER_AUDIO(state, audioFiles) {
  //   state.audioFiles = audioFiles;
   
  // },
  SET_USER_AUDIO(state, audioFiles) {
    console.log('audioFiles',audioFiles)
    // Сортируем файлы перед установкой
    state.audioFiles = audioFiles.sort((a, b) => {
      let dateA = new Date(a.AddedDT);
      let dateB = new Date(b.AddedDT);
      return dateB - dateA; // От нового к старому
    });
  },
  // SET_USER_AUDIO(state, audioFiles) {
  //   console.log('audioFiles',audioFiles)
  //   // Сортируем файлы перед установкой
  //   state.currentUser.audioFiles = audioFiles.sort((a, b) => {
  //     let dateA = new Date(a.AddedDT);
  //     let dateB = new Date(b.AddedDT);
  //     return dateB - dateA; // От нового к старому
  //   });
  // },

  // SET_AUDIOSTATUS(state, elem) {
  //   console.log('Setting audio status for index:', elem.index, 'status:', elem.status);
  //   if (state.audioFiles) {
  //     state.audioFiles.forEach((item, idx) => {
  //       // console.log('Processing audio file:', idx, item);
  //       item.audioStatus = idx === elem.index ? elem.status : 'stopped';
  //       if (elem.index === idx && elem.time !== undefined) {
  //         item.currentTime = elem.time;
  //       }
  //     });
  //   } else {
  //     console.warn("Audio files not found in currentUser.");
  //   }
  // },

  SET_AUDIOSTATUS(state, { fileId, status, time }) {
    console.log('Setting audio status for FileId:', fileId, 'status:', status);
    if (state.audioFiles) {
      state.audioFiles.forEach((item) => {
        if (item.FileId === fileId) {
          item.audioStatus = status;
          if (time !== undefined) {
            item.currentTime = time;
          }
        } else {
          item.audioStatus = 'stopped';
        }
      });
    } else {
      console.warn("Audio files not found.");
    }
  },
  
  
  UpdateTripFlag(state, value) {
    state.currentUser.TripFlag = value;
  },
  UpdateRemoteFlag(state, value) {
    state.currentUser.RemoteFlag = value;
  },
  UpdateAvatarId(state, value) {
    state.currentUser.AvatarId = value;
  },
}  
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
